import { Component, Vue } from 'nuxt-property-decorator'
import FlexibleContainer from '~/components/flexible/FlexibleContainer.vue'
import LazyLoad from 'vanilla-lazyload'

@Component({})
export class PageMixin extends Vue {
  showBreadcrumbs: boolean = true
  hasAccess: boolean | null = null

  public accessIds: { [key: string]: string } = {
    press: '492',
    teacher: '493',
    volunteer: '494',
    docent: '495',
  }

  async fetch() {
    let method = 'getPost'

    // if previewing a page or post
    if (
      this.$route.query &&
      (this.$route.query.preview_id ||
        this.$route.query.page_id ||
        this.$route.query.p)
    ) {
      method = 'getPreview'
    }

    const path =
      this.$route && this.$route.params ? this.$route.params.pathMatch : ''

    if (!this.postLoaded || this.post.item.slug !== path) {
      const result = await this.$store.dispatch(`post/${method}`, this.$route)

      return result
    }
  }

  /**
   * Mounted hook, will merge with every page that uses this mixin
   * This is where to put code that will usually fire on DOM ready
   *
   */
  async mounted() {
    var lazyLoadInstance = new LazyLoad({
      elements_selector: '.lazy',
    })
    if (lazyLoadInstance) {
      lazyLoadInstance.update()
    }

    await this.checkAccess()

    this.$nextTick(() => {
      this.$nuxt.$loading.start()
      setTimeout(() => {
        this.$nuxt.$loading.finish(), 500
      })
    })
  }

  get post(): any {
    return this.$store.state.post
  }

  get postLoaded(): boolean {
    return this.post && Object.keys(this.post.item).length !== 0
  }

  get access(): string {
    return this.post.access
  }

  async checkAccess(): Promise<void> {
    if (this.access != 'public') {
      if (!this.$store.getters['customer/isLoggedIn']) {
        this.hasAccess = false
      } else {
        this.hasAccess = await this.$store
          .dispatch('customer/getCustomerAttributes')
          .then((attributes) => {
            const check = attributes.find(
              (att) => att.keyword_ref == this.accessIds[this.access]
            )
            return check ? true : false
          })
      }
    } else {
      this.hasAccess = true
    }
  }

  head() {
    return {
      title: this.post.item.title,
      bodyAttrs: {
        class: 'homepage',
      },
    }
  }
}

@Component({
  components: {
    FlexibleContainer,
  },
})
/**
 * Flexible component mixin, apply to any page with flexible components
 * to access helper methods
 */
export class HasFlexibleComponents extends Vue {
  get pageHasComponents() {
    return this.post.components && Object.keys(this.post.components).length > 0
  }

  get components() {
    return this.pageHasComponents ? this.post.components : {}
  }

  get fixed() {
    return this.post.fixed ? this.post.fixed : []
  }

  get breadcrumbs() {
    return this.fixed && this.fixed.breadcrumbs && this.fixed.breadcrumbs.data
      ? {
          enabled: this.fixed.breadcrumbs.data.enabled,
          items: this.fixed.breadcrumbs.data.items,
        }
      : {
          enabled: false,
          items: [],
        }
  }

  /**
   * Banner should not be flexible because we don't want it placed randomly on the page
   * So we have it as part of the elements attribute
   */
  get elements(): any {
    return this.post.elements ? this.post.elements : []
  }

  get hasBanner(): boolean {
    if (this.elements.banner !== undefined) {
      return this.elements.banner.data.banner.data.enabled
    }
    return false
  }

  /**
   * Convenience method to access the post item
   */
  get item() {
    return this.post.item ? this.post.item : {}
  }

  /**
   * Convenience method to access the post item
   */
  get images() {
    return this.post.images ? this.post.images : {}
  }

  /**
   * Convenience method to access the author
   */
  get author() {
    return this.post.author ? this.post.author : ''
  }

  get post(): any {
    return this.$store.state.post
  }
}

export interface IPageMixins extends PageMixin, HasFlexibleComponents {}
