import { render, staticRenderFns } from "./FaqControls.vue?vue&type=template&id=4346c25a&"
import script from "./FaqControls.vue?vue&type=script&lang=ts&"
export * from "./FaqControls.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports