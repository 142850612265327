
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Faq from '~/components/flexible/faq/Faq.vue'

interface IFaqQuestion {
  data: {
    question: string
    answer: string
  }
}

interface IFaq {
  title?: string
  subtitle?: string
  categories?: string
  questions?: Array<IFaqQuestion>
}

@Component({
  components: {
    Faq
  }
})
export default class Faqs extends Vue {
  @Prop({ type: Object }) block?: IFaq

  get categoryData() {
    return this.block.categories ? JSON.parse(this.block.categories) : []
  }
}
