
import { Vue, Component, Prop } from 'vue-property-decorator'

import RelatedContent from '~/components/flexible/RelatedContent.vue'
import Banner from '~/components/flexible/Banner.vue'
import Ctas from '~/components/flexible/Ctas.vue'
import Wysiwyg from '~/components/flexible/Wysiwyg.vue'
import ImageCarousel from '~/components/flexible/ImageCarousel.vue'
import Venues from '~/components/flexible/Venues.vue'
import EventCalendar from '~/components/flexible/EventCalendar.vue'
import GalleryWithText from '~/components/flexible/GalleryWithText.vue'
import Memberships from '~/components/flexible/Memberships.vue'
import Faqs from '~/components/flexible/Faqs.vue'
import ContactUs from '~/components/flexible/ContactUs.vue'
import Embed from '~/components/flexible/Embed.vue'
import Quote from '~/components/flexible/Quote.vue'
import Resources from '~/components/flexible/Resources.vue'
import BridgeEvents from '~/components/flexible/BridgeEvents.vue'
import ListItems from '~/components/flexible/ListItems.vue'
import SocialFeed from '~/components/flexible/SocialFeed.vue'
import Donations from '~/components/flexible/Donations.vue'
import ImageSingle from '~/components/flexible/ImageSingle.vue'
import ImageGrid from '~/components/flexible/ImageGrid.vue'

@Component({
  components: {
    Banner,
    Ctas,
    RelatedContent,
    Wysiwyg,
    ImageCarousel,
    Venues,
    EventCalendar,
    GalleryWithText,
    Memberships,
    Faqs,
    ContactUs,
    Embed,
    Quote,
    Resources,
    BridgeEvents,
    ListItems,
    SocialFeed,
    Donations,
    ImageSingle,
    ImageGrid
  }
})
export default class FlexibleContainer extends Vue {
  @Prop(Object) components

  /**
   * Slightly messy way to listen to events from the mach forms iframe
   */
  handleIFrameMessage(event): void {
    setTimeout(() => {
      if (event.origin.indexOf('forms.') !== -1) {
        const form = document.querySelector('iframe[src^="https://forms."]')
        const outer = form.closest('.wysiwyg')
        if (form && outer) {
          const height = event.data.match(/\d+/g).map(Number)[0]

          form.setAttribute('height', height)

          if (height < 1000 && height > 0) {
            window.scrollTo({ top: outer.offsetTop })
          }
        }
      }
    }, 2000)
  }

  /**
   * Update lazy load instance when components are mounted
   */
  mounted() {
    if (this.$lazyload) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$lazyload.update()
        }, 500)
      })
    }

    window.addEventListener('message', this.handleIFrameMessage)
  }

  beforeDestroy(): void {
    window.removeEventListener('message', this.handleIFrameMessage)
  }
}
