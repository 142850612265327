
import { Component, Vue, Prop } from 'nuxt-property-decorator'
@Component({})
export default class OnThisWeekTabControls extends Vue {
  @Prop() tabs?: Array<any>

  public selectedTab: string | null = null

  onSelectTab(tab: string): void {
    this.selectedTab = tab
    this.emitSelect(this.selectedTab)
  }

  emitSelect(tab: string): void {
    this.$emit('on-this-week:tab-select', this.selectedTab)
  }

  created(): void {
    if (!this.selectedTab && this.tabs[0]) {
      this.selectedTab = this.tabs[0].type
      this.emitSelect(this.selectedTab)
    }
  }
}
