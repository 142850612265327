
import { Component, mixins } from 'nuxt-property-decorator'
import CalendarPackages from '~/components/flexible/calendar/CalendarPackages.vue'
import CalendarBlockDayAvailabilityZone from '~/components/flexible/calendar/CalendarBlockDayAvailabilityZone.vue'

@Component({
  components: {
    CalendarBlockDayAvailabilityZone,
  },
})
export default class CalendarBlockDayAvailability extends mixins<CalendarPackages>(
  CalendarPackages
) {}
