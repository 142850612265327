import { Component } from 'nuxt-property-decorator'
import LoadMoreMixin from '~/components/ui/mixins/LoadMoreMixin'

/**
 * Use the paginatable mixin in any list component that requires pagination
 *
 * You will need to set the paginatedDataKey string on the component
 *
 * e.g public paginatedDataKey: string = 'events'
 *
 * This should correspond to a prop, data or computed on the component,
 * you should then be able to loop around the paginatedData computed prop
 * to display the list elements.
 *
 * The component must also have a direct child Pagination component
 *
 * Please see, ~/components/events/EventGrid.vue for an example of how to use
 */

@Component
export default class Paginatable extends LoadMoreMixin {
  /**
   * Computed property returns array of paginated data
   *
   * @return Array<any>
   */
  get paginatedData(): Array<any> {
    return this[this.paginatedDataKey] && this[this.paginatedDataKey].length
      ? this[this.paginatedDataKey].slice(this.start - 1, this.stop)
      : []
  }

  /**
   * Calculate pagination start
   *
   * @return number
   */
  get start(): number {
    return (this.currentPage - 1) * this.perPage + 1
  }

  /**
   * The current page property fill filter down to recalculate the paginated data
   *
   * @param number - page to change to
   */
  onChangePage(page: number): void {
    if (page <= this.totalPages) {
      this.currentPage = page
      window.scrollTo(0, 0)
    }
  }
}
