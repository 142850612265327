
import { Vue, Component, Prop } from 'vue-property-decorator'
import Cta from '~/components/flexible/ctas/Cta.vue'
import { CtaBlock } from '~/components/flexible/types/Cta'

@Component({
  components: {
    Cta
  }
})
export default class CtaRow extends Vue {
  @Prop(Array) block?: Array<CtaBlock>

  get modifier(): string {
    if (this.block) {
      return this.block.length === 2
        ? 'block-cta-row--double'
        : 'block-cta-row--triple'
    }

    return "block-cta-row--triple";
  }
}
