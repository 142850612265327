
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export interface ImageGridInterface {
  title: string
  images: {
    data: {
      image: {
        url: string
        alt: string
      }
      link: {
        url: string
        target: string
        title: string
      }
    }
  }
}

@Component
export default class ImageGrid extends Vue {
  @Prop({ type: Object, required: true }) block!: ImageGridInterface

  get images() {
    return this.block.images
      ? this.block.images.map((image) => {
          return {
            image: image.data.image,
            link: image.data.link
          }
        })
      : []
  }

  get gridClass() {
    if (this.images.length % 2 === 0) {
      return 'image-grid__inner--even'
    }

    return 'image-grid__inner--odd'
  }

  mounted() {
    if (this.$lazyload) {
      this.$nextTick(() => this.$lazyload.update())
    }
  }
}
