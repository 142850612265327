
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Mixins } from 'vue-mixin-decorator'

import { IPaginatable } from '~/components/ui/mixins/LoadMoreMixin'
import Paginatable from '~/components/ui/mixins/Paginatable'
import Pagination from '~/components/ui/Pagination.vue'

import EventCard from '~/components/cards/EventCard.vue'
import ProductCard from '~/components/cards/ProductCard.vue'
import NewsCard from '~/components/cards/NewsCard.vue'
import PageCard from '~/components/cards/PageCard.vue'

@Component({
  components: {
    EventCard,
    ProductCard,
    NewsCard,
    PageCard,
    Pagination
  }
})
export default class CardGrid extends Mixins(Paginatable)
  implements IPaginatable {
  @Prop({ type: Object }) block?: any

  public perPage: number = 6
  public paginatedDataKey: string = 'cards'

  get cardComponentName(): string {
    return ` ${this.block.type} Card`
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }

  get cards() {
    return this.block.cards
  }

  getEvent(card) {
    const event = (card.data) ? card.data : card
    return (event.post_extra) ? event.post_extra : event
  }
}
