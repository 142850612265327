
import { Component, Prop, Vue } from 'nuxt-property-decorator'

/**
 * The pagination component should work in any list component that
 * requires paginating.  It must be a direct child of the list component
 *
 * The list component should use the Paginatable mixin or implement the
 * methods itself in order for this to work
 */
@Component
export default class Pagination extends Vue {
  @Prop({ type: Number }) pages?: number
  @Prop({ type: Number }) currentPage?: number

  /**
   * Emits an event to the parent when clicked
   *
   * @param number - page number
   *
   * @return void
   */
  onClickPaginationItem(page: number): void {
    if (this.currentPage !== page) {
      this.$emit('pagination:change-page', page)
    }
  }
}
