
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import CtaSingle from '~/components/flexible/ctas/CtaSingle.vue'
import CtaRow from '~/components/flexible/ctas/CtaRow.vue'
import CtaColourBlock from '~/components/flexible/ctas/CtaColourBlock.vue'

export interface CtaCardData {
  title?: string
  description?: string
  url?: string
  button_text?: string
  image_src?: string
}

export interface CtaCard {
  data?: CtaCardData
}

export interface CtaBlock {
  type?: string
  cards?: Array<CtaCard>
}

@Component({
  components: {
    CtaSingle,
    CtaRow,
    CtaColourBlock,
  },
})
export default class Ctas extends Vue {
  @Prop({ type: Object }) block?: CtaBlock

  get cards(): Array<CtaCard> {
    return this.block ? this.block.cards : []
  }

  get firstCard(): CtaCardData {
    return this.cards.length > 0 ? this.cards[0].data : null
  }

  get isColourBlock(): boolean {
    return this.cards.length > 0
      ? this.cards[0].data.cta_type === 'colour_block'
      : false
  }
}
