
import { Component, Prop, Vue } from 'nuxt-property-decorator'

interface Image {
  sizes: { large: string }
}

interface Link {
  target: string
  title: string
  url: string
}

@Component
export default class ImageCarouselImage extends Vue {
  @Prop({ type: Object, required: true }) image!: {
    description: string
    image: Image
    link: Link
    title: string
  }

  onClickCta(): void {
    // if internal link and hasnt been set to target _blank, use nuxt link
    if (this.isInternal && this.image.link.target == '' && !this.isMedia) {
      this.$router.push(this.image.link.url.replace(/^https?:\/\/(..)+\//, '/'))
    } else {
      window.open(this.image.link.url, '_blank')
    }
  }

  get isInternal(): boolean {
    if (
      this.image.link &&
      this.image.link.url &&
      (this.image.link.url.includes(
        this.$config.get('APP_URL').replace(/^https?:\/\//, '')
      ) ||
        !this.image.link.url.includes('http'))
    ) {
      return true
    } else {
      return false
    }
  }

  get isMedia(): boolean {
    return this.image.link && this.image.link.url.indexOf('.') !== -1
  }
}
