
import { Component, Vue, Prop } from 'nuxt-property-decorator'

import * as instance from '~/api/queries/instance.gql'
import OnThisWeekTabControls from '~/components/flexible/related/OnThisWeekTabControls.vue'
import OnThisWeekCarousel from '~/components/flexible/related/OnThisWeekCarousel.vue'
import { Event } from '~/@types/skyway'

@Component({
  components: {
    OnThisWeekTabControls,
    OnThisWeekCarousel
  }
})
export default class OnThisWeek extends Vue {
  @Prop({ type: Object }) block?: any

  public loading?: boolean = false
  public filteredEvents?: Array<Event> = []

  /**
   * We change the key on the carousel component to force it to re-render
   * when the events change, ensuring it gets destroyed and re-initialised
   */
  public carouselKey?: number = 1

  onTabSelect(tab) {
    const events = this.block.events.flat()

    if (tab != 'all') {
      this.filteredEvents = events.filter((event) => {
        return tab == event.type
      })
    } else {
      this.filteredEvents = events
    }

    this.carouselKey++
  }

  get fromDate() {
    if (this.block.start_date != '') {
      return this.$moment(this.block.start_date)
    } else {
      return this.$moment()
    }
  }

  get toDate() {
    if (this.block.end_date != '') {
      return this.$moment(this.block.end_date)
    } else {
      return this.$moment().endOf('isoWeek')
    }
  }

  get eventTypes() {
    return this.$store.state.events.types
  }

  get tabs(): Array<string> {
    if (this.eventTypes && this.block.event_type[0] == 'all') {
      return [
        {
          tabName: 'All',
          type: 'all'
        },
        {
          tabName: 'Tours',
          type: this.eventTypes.tour
        },
        {
          tabName: 'Films',
          type: this.eventTypes.film
        },
        {
          tabName: 'Performances',
          type: this.eventTypes.performance
        },
        {
          tabName: 'Lectures',
          type: this.eventTypes.lecture
        },
        {
          tabName: 'Member Events',
          type: this.eventTypes.member
        },
        {
          tabName: 'Free',
          type: this.eventTypes.free
        },
        {
          tabName: 'Exhibitions',
          type: this.eventTypes.exhibition
        }
      ]
    } else {
      return Array.isArray(this.block.event_type)
        ? this.block.event_type.reduce((filtered, type) => {
            filtered.push({ tabName: type, type })

            return filtered
          }, [])
        : this.block.event_type
    }
  }
}
