
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import sumBy from 'lodash/sumBy'
import CalendarPerformances from '~/components/flexible/calendar/CalendarPerformances.vue'
import CalendarZones from '~/components/flexible/calendar/CalendarZones.vue'
import CalendarPackages from '~/components/flexible/calendar/CalendarPackages.vue'
import CalendarDay from '~/components/flexible/calendar/CalendarDay.vue'
// @ts-ignore
import SvgChevronLeft from '~/static/images/sprites/chevron-left.svg'
// @ts-ignore
import SvgChevronRight from '~/static/images/sprites/chevron-right.svg'
import { Event, Instance, Package } from '~/@types/skyway'
import { types } from '~/store/events'
import { config } from '~/config'

@Component({
  components: {
    CalendarPerformances,
    CalendarZones,
    CalendarPackages,
    CalendarDay,
    SvgChevronRight,
    SvgChevronLeft,
  },
})
export default class CalenderBlockCalendar extends Vue {
  @Prop() event!: Event | null
  @Prop() packages!: Package[] | null
  @Prop({ type: Boolean, default: true }) loading

  public today = this.$moment()
  public dateContext = this.$moment()
  public days: Array<any> = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  public daySelected: boolean = false

  @Watch('nextInstanceDate')
  updateDate(newVal) {
    this.dateContext = this.$moment(this.nextInstanceDate)
    this.setDateContext()
  }

  /**
   * Convenience getters
   */
  get year(): string {
    return this.dateContext.format('YYYY')
  }

  get month(): string {
    return this.dateContext.format('MMMM')
  }

  get daysInMonth(): number {
    return this.dateContext.daysInMonth()
  }

  get currentDate(): number {
    return this.dateContext.get('date')
  }

  get firstDayOfMonth(): number {
    const firstDay = this.$moment(this.dateContext).subtract(
      this.currentDate - 1,
      'days'
    )
    return firstDay.weekday()
  }

  get initialDate(): number {
    return this.today.get('date')
  }

  get initialMonth(): string {
    return this.today.format('MMMM')
  }

  get initialYear(): string {
    return this.today.format('Y')
  }

  get selectedDate(): any {
    if (this.isPackagesCalendar) {
      return this.$store.state.packages.selectedDate
    } else {
      return this.$store.state.events.selectedDate
    }
  }

  get hasEventInstances(): boolean {
    return Boolean(
      this.event && this.event.instances && this.event.instances.length > 0
    )
  }

  get instances(): Array<Instance> | null {
    const date = this.$store.state.events.selectedDate

    if (date && this.event && this.event.instances) {
      return (
        this.event.instances &&
        this.event.instances.filter(
          (instance) =>
            instance &&
            this.$moment(instance.date_time).isSame(
              `${date.format('YYYY')}-${date.format('MM')}-${date.format(
                'DD'
              )}`,
              'day'
            )
        )
      )
    } else {
      return null
    }
  }

  get instance(): Instance {
    return this.instances && this.instances.length === 1
      ? this.instances[0]
      : false
  }

  get isPackagesCalendar() {
    return !this.event && this.packages && this.packages.length
  }

  get selectedPackages() {
    if (this.packages) {
      const selectedDate = this.$moment(this.dateContext)
      const packages = this.packages.filter((p) => {
        return p.instances.find((i) => {
          const instanceDateTime = this.$moment(i.date_time)
            .tz(config.TIMEZONE)
            .format()
          return selectedDate.isSame(instanceDateTime, 'day')
        })
      })
      return packages
    }
    return null
  }

  get limitedAvailability() {
    return this.event && this.event.limited_availability
      ? this.event.limited_availability
      : 5
  }

  onClickNext(): void {
    this.dateContext = this.$moment(this.dateContext).add(1, 'month')
    this.setDateContext()
  }

  onClickLast(): void {
    this.dateContext = this.$moment(this.dateContext).subtract(1, 'month')
    this.setDateContext()
  }

  setChosenDay(day: number): void {
    this.dateContext = this.$moment(this.dateContext).set('date', day)
    this.setDateContext()

    if (this.event && this.event.type === 'field-trip') {
      if (this.instance) {
        this.$router.push(`/purchase/schools/${this.instance.instance_ref}/`)
      } else {
        this.daySelected = true
      }
    } else {
      this.daySelected = true
    }
  }

  onMouseOverDay(day: number): void {
    this.setChosenDay(day)
  }

  onSelectDay(day: number): void {
    this.setChosenDay(day)
    this.daySelected = true
  }

  onClickBack(): void {
    this.daySelected = false
  }

  setDateContext() {
    if (this.isPackagesCalendar) {
      this.$store.commit(
        `packages/${types.SET_SELECTED_DATE}`,
        this.dateContext
      )
    } else {
      this.$store.commit(`events/${types.SET_SELECTED_DATE}`, this.dateContext)
    }
  }

  /**
   * Get instances on a particular day
   */
  instancesByDay(day: number): any[] {
    let instances: Instance[] = []
    if (this.isPackagesCalendar) {
      instances = []
      this.packages.forEach((p) => {
        if (p.instances) {
          p.instances.forEach((instance: PackageInstance) => {
            if (
              p &&
              p.price_types &&
              p.price_types[0] &&
              p.price_types[0].prices
            ) {
              const price_types = p.price_types[0].prices.filter(
                (pt) => pt.instance_ref === instance.instance_ref
              )
              instance.total_availability = sumBy(
                price_types,
                (pt) => pt.availability
              )
            }
          })

          instances = instances.concat(p.instances)
        }
      })
    } else if (this.event) {
      instances = (this.event.instances as Instance[]) || []
    }

    if (instances && instances.length) {
      const date = day < 10 ? `0${day}` : day
      const targetDate = this.$moment(
        `${this.dateContext.format('YYYY')}-${this.dateContext.format(
          'MM'
        )}-${date}`
      )

      return instances.filter((instance) => {
        const instanceDate = this.$moment(instance.date_time)
          .tz(config.TIMEZONE)
          .format('YYYY-MM-DD')
        return this.$moment(instanceDate).isSame(targetDate, 'day')
      })
    }

    return []
  }

  get nextInstanceDate() {
    if (
      this.isPackagesCalendar &&
      this.packages &&
      this.packages.length &&
      this.packages[0].instances.length
    ) {
      return this.packages[0].instances[0].date_time
    } else if (
      this.event &&
      this.event.instances &&
      this.event.instances.length
    ) {
      return this.event.next_instance.date_time
    }
  }

  /**
   * Commit today's date to the store when the component mounts
   */
  mounted(): void {
    if (this.nextInstanceDate) {
      this.dateContext = this.$moment(this.nextInstanceDate)
    }

    this.setDateContext()
  }
}
