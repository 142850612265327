
import { Component, Mixins } from 'vue-mixin-decorator'

import { PageMixin, HasFlexibleComponents } from '~/mixins/PageMixins'
import Banner from '~/components/flexible/Banner.vue'
import StoryPage from '~/pages/stories/_.vue'

/**
 * Home page component, roughly matches page-home.php in wordpress hierarchy
 */
@Component({
  components: {
    Banner,
    StoryPage,
  },
})
export default class HomePage extends Mixins(PageMixin, HasFlexibleComponents) {
  showBreadcrumbs = false

  get isStoryPreview() {
    return (
      this.post.item.post_type == 'post' &&
      this.$route.query &&
      this.$route.query.preview &&
      this.$route.query.preview == 'true'
    )
  }

  get post(): any {
    return this.$store.state.post
  }
}
