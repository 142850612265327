
import { Component, Prop, Vue } from 'nuxt-property-decorator'

interface IPrice {
  description: string
  value: string
}

@Component
export default class CalendarInformation extends Vue {
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) description?: string
  @Prop({ type: Array | Boolean }) prices?: Array<IPrice>
  @Prop({ type: String }) additionalText?: string
  @Prop({ type: String }) link?: string
  @Prop({ type: String }) buttonText?: string

  get hasPrices() {
    return this.prices.length > 0 ? this.prices[0].description !== '' : false
  }
}
