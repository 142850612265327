
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import OurVenuesVenueGettingHere from '~/components/flexible/our-venues/OurVenuesVenueGettingHere.vue'
import { Facility } from '~/@types/skyway'

@Component({
  components: {
    OurVenuesVenueGettingHere
  }
})
export default class OurVenuesVenue extends Vue {
  @Prop({ type: Object }) venue?: Facility
}
