
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Event, Instance } from '~/@types/skyway'
import CalendarZone from '~/components/flexible/calendar/CalendarZone.vue'
import ChevronLeftSvg from '~/static/images/sprites/chevron-left.svg'
import BookNowButton from '~/components/ui/BookNowButton'

@Component({
  components: {
    CalendarZone,
    ChevronLeftSvg,
    BookNowButton,
  },
})
export default class CalendarZones extends Vue {
  @Prop(Object) event!: Event

  /**
   * Will be zone id
   */
  public selected: number = 0

  onSelectZone(id: Number): void {
    this.selected = id
    this.$store.commit('selection/SET_ZONE', this.selected)
  }

  get instances(): Array<Instance> {
    const date = this.$store.state.events.selectedDate

    return this.event.instances.filter((instance) =>
      this.$moment(instance.date_time).isSame(
        `${date.format('YYYY')}-${date.format('MM')}-${date.format('DD')}`,
        'day'
      )
    )
  }

  get instance(): Instance {
    return this.instances.length ? this.instances[0] : false
  }

  /**
   * @todo handle error state
   */
  onClickBookNow(): void {
    if (this.selected) {
      if (this.instances.length === 1) {
        this.$router.push(`/purchase/tickets/${this.instance.instance_ref}/`)
      } else {
        // throw error
      }
    }
  }

  mounted() {
    // if only one zone, just select it
    if (this.instance.availability.length == 1) {
      this.$store.commit(
        'selection/SET_ZONE',
        this.instance.availability[0].zone_ref
      )
      this.$router.push(`/purchase/tickets/${this.instance.instance_ref}/`)
    }
  }
}
