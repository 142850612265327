
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import { Zone } from '~/@types/skyway'

@Component
export default class CalendarPackageZones extends Vue {
  @Prop({ type: Object }) zone?: Zone

  get availability() {
    if (this.zone && this.zone.availability && this.zone.availability > 0) {
      return `${this.zone.availability} spaces left`
    } else {
      return `Sold out`
    }
  }
}
