
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import { Instance } from '~/@types/skyway'
import CalendarInstance from '~/components/flexible/calendar/CalendarInstance.vue'
// @ts-ignore
import ChevronLeftSvg from '~/static/images/sprites/chevron-left.svg'
import BookNowButton from '~/components/ui/BookNowButton.vue'

const instance = namespace('instance')

@Component({
  components: {
    CalendarInstance,
    ChevronLeftSvg,
    BookNowButton,
  },
})
export default class CalendarPerformances extends Vue {
  @Prop() instances!: Instance[]
  @Prop() type!: string

  @instance.State
  instance

  /**
   * Will be zone id
   */
  public selected: number = 0

  onSelectInstance(id: number): void {
    this.selected = id
    const instance = this.instances.find(
      (i) => Number(i.instance_ref) === Number(this.selected)
    )
    this.$store.commit('instance/SET_INSTANCE', instance)

    if (this.type === 'field-trip') {
      this.$router.push(`/purchase/schools/${this.instance.instance_ref}/`)
    } else {
      this.$router.push(`/purchase/tickets/${this.instance.instance_ref}/`)
    }
  }

  /**
   * @todo handle error state
   */
  onClickBookNow(): void {
    if (this.selected) {
      if (this.instances.length === 1) {
        this.$router.push(`/purchase/tickets/${this.instance.instance_ref}/`)
      } else {
        // throw error
      }
    }
  }
}
