
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import OurVenuesVenue from '~/components/flexible/our-venues/OurVenuesVenue.vue'
import { Venue } from '~/@types/skyway'

@Component({
  components: {
    OurVenuesVenue
  }
})
export default class OurVenuesTabGroup extends Vue {
  @Prop() venues!: Array<Venue>

  selectedVenue = 0

  handleSelectorChange(index): void {
    this.selectedVenue = index
  }
}
