
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Instance } from '~/@types/skyway'
import { sumBy } from 'lodash'

@Component
export default class CalendarInstance extends Vue {
  @Prop({ type: Object }) instance!: Instance
  @Prop() zoneFilter?: number[]

  get date() {
    if (this.instance.date_time) {
      return this.$moment(this.instance.date_time).format('LT')
    } else {
      return `Sold out`
    }
  }

  get availability() {
    if (this.zoneFilter && this.zoneFilter.length) {
      const zones = this.instance.availability.filter((z) =>
        this.zoneFilter.includes(Number(z.zone_ref))
      )
      if (zones) {
        return sumBy(zones, (z) => z.availability)
      }
    }
    return this.instance.total_availability
  }
}
