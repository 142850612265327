
import { Component, Vue, Prop } from 'vue-property-decorator'
import Modal from '~/components/ui/Modal.vue'
import { Membership } from '~/store/types'

@Component({
  components: {
    Modal
  }
})
export default class MembershipCard extends Vue {
  @Prop(Object) membership?: Membership

  async addMembership(): Promise<any> {
    await this.$store.dispatch('memberships/addMembership', this.membership)
    this.$router.push('/cart/')
  }

  async upgradeMembership(): Promise<any> {
    await this.$store.dispatch('memberships/upgradeMembership', this.membership)
    this.$router.push('/cart/')
  }

  async renewMembership(): Promise<any> {
    await this.$store.dispatch('memberships/renewMembership', this.membership)
    this.$router.push('/cart/')
  }

  get membershipStatus() {
    if (
      this.$store.state.customer != undefined &&
      this.$store.state.customer.customer != undefined &&
      this.$store.state.customer.customer.memberships &&
      this.$store.state.customer.customer.memberships.length > 0
    ) {
      for (const k in this.$store.state.customer.customer.memberships) {
        if (
          this.$moment(
            this.$store.state.customer.customer.memberships[k].dates.expiration
          ) > this.$moment()
        ) {
          return 'active' // make sure we have non-expired membership
        }
      }
    }
    return 'non-member'
  }

  get currentMembership() {
    if (this.membershipStatus == 'active') {
      return this.$store.state.customer.customer.memberships[0]
    } else {
      return false
    }
  }

  get membershipLevel() {
    if (this.currentMembership) {
      return this.currentMembership.membership_type.level_ref
    } else {
      return false
    }
  }

  get membershipValue() {
    if (this.currentMembership) {
      return this.currentMembership.membership_type.start_amount
    } else {
      return false
    }
  }

  get renewalDate() {
    if (this.currentMembership) {
      return this.$moment(this.currentMembership.dates.renewal)
    } else {
      return false
    }
  }

  get lapseDate() {
    if (this.currentMembership) {
      return this.$moment(this.currentMembership.dates.lapse)
    } else {
      return false
    }
  }

  get isInRenewalPeriod() {
    if (this.renewalDate && this.lapseDate) {
      return (
        this.renewalDate > this.$moment() && this.lapseDate < this.$moment()
      )
    } else {
      return false
    }
  }

  /**
   * mock for now as we're not using HoMA data
   */
  typeKey(title) {
    if (title.toLowerCase().includes('individual')) {
      return 'BASIC'
    }
    if (title.toLowerCase().includes('contributing')) {
      return 'CONTRIBUTING'
    }
    if (title.toLowerCase().includes('supporting')) {
      return 'SUPPORTING'
    }
    if (title.toLowerCase().includes('collectors')) {
      return 'COLLECTORS'
    }
    if (title.toLowerCase().includes('ambassador')) {
      return 'AMBASSADOR'
    }
    if (title.toLowerCase().includes('advocate')) {
      return 'ADVOCATE'
    }
    if (title.toLowerCase().includes('leader')) {
      return 'LEADER'
    }
    if (title.toLowerCase().includes("director's circle")) {
      return 'DIRECTORS_CIRCLE'
    }
    if (title.toLowerCase().includes("chairman's circle")) {
      return 'CHAIRMANS_CIRCLE'
    }
    if (title.toLowerCase().includes('patron')) {
      return 'PATRON'
    }
    if (title.toLowerCase().includes('visionary circle')) {
      return 'VISIONARY_CIRCLE'
    }
  }
}
