
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { initCarousel } from '~/modules/carousel/initCarousel'

export interface GalleryWithText {
  alignment: string
  background_colour: string
  description: string
  images: Array<any>
  link: any
  title: string
}

const listenResize = (element, pagination) => {
  const image = element.querySelector('.two-column-carousel__item-image')
  let imageHeight = image.clientHeight
  pagination.style.top = `${imageHeight - 50}px`

  window.addEventListener('resize', () => {
    imageHeight = image.clientHeight
    pagination.style.top = `${imageHeight - 50}px`
  })
}

@Component
export default class GalleryWithText extends Vue {
  @Prop({ type: Object }) block?: GalleryWithText

  get showPagination(): boolean {
    return this.block.images.length > 1
  }

  initCarousel(): void {
    Vue.nextTick(() => {
      if (this.$refs.carousel) {
        const paginationContainer = this.$refs.carousel.querySelector(
          '[data-pagination]'
        )
        const carouselElement = this.$refs.carousel.querySelector(
          '[data-carousel]'
        )
        initCarousel({
          carouselElement,
          paginationContainer,
          perPage: 1,
          duration: 500,
          onInit() {
            this._addSlideIds(this)
            this.innerElements[0].parentNode.setAttribute(
              'data-slide-state',
              'active'
            )
          },
          onChange() {
            this._updatePagination(this)
            this._disablePaginationArrows(this, paginationContainer)
            this._assignSlideStates(this)
          },
          loop: false
        })
      }
    })
  }

  onClickCta(link): void {
    // if internal link and hasnt been set to target _blank, use nuxt link
    if (this.isInternal(link.url) && (!link.target || link.target == '')) {
      this.$router.push(link.url.replace(/^https?:\/\/(..)+\//, '/'))
    } else {
      window.open(link.url, '_blank')
    }
  }

  isInternal(url): boolean {
    if (
      url &&
      (url.includes(this.$config.get('APP_URL').replace(/^https?:\/\//, '')) ||
        !url.includes('http'))
    ) {
      return true
    } else {
      return false
    }
  }

  mounted(): void {
    if (
      this.block.images &&
      this.block.images.length > 1 &&
      this.$refs.carousel
    ) {
      this.initCarousel()
    }
  }
}
