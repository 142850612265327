
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import OnThisWeek from '~/components/flexible/related/OnThisWeek.vue'
import CardGrid from '~/components/flexible/related/CardGrid.vue'

@Component({
  components: {
    OnThisWeek,
    CardGrid
  }
})
export default class BridgeEvents extends Vue {
  @Prop({ block: Object }) block?: any

  get gridFormat() {
    return {
      ...this.block,
      type: 'event',
      cards: this.block.events[0]
    }
  }
}
