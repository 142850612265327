
import { Component, mixins } from 'nuxt-property-decorator'
import CalendarPackageZones from '~/components/flexible/calendar/CalendarPackageZones.vue'

@Component
export default class CalendarBlockDayAvailabilityZone extends mixins<CalendarPackageZones>(
  CalendarPackageZones
) {
  get availability() {
    if (this.zone && this.zone.availability && this.zone.availability > 0) {
      return `${this.zone.availability} slots`
    } else {
      return `0 slots`
    }
  }

  get availabilityClass(): string {
    const total = 25

    const percentage =
      this.zone && this.zone.availability
        ? Math.floor((this.zone.availability / total) * 100)
        : 0

    return percentage === 0
      ? 'is-sold-out'
      : percentage <= 50 && percentage > 25
      ? 'is-selling-out'
      : percentage <= 25
      ? 'is-limited'
      : ''
  }
}
