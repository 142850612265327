
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import FaqControlRadio from '~/components/flexible/faq/FaqControlRadio.vue'

@Component({
  components: {
    FaqControlRadio
  }
})
export default class FaqControls extends Vue {
  @Prop({ type: Array }) categories?: Array<any>

  public selectedCategories?: Array<number> = []
  public searchTerm?: string = ''

  @Watch('searchTerm')
  onSearchTermChanged(val) {
    this.$emit('faq:search-changed', val)
  }

  @Watch('selectedCategories')
  onCategoriesChanged(val) {
    this.$emit('faq:categories-changed', val)
  }
}
