
import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'

type InstagramPost = {
  imageSrc: string
  url: string
  content: string
  username: string
}

@Component
export default class InstagramCard extends Vue {
  @Prop() card?: InstagramPost
}
