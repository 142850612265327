
import { Component, Vue } from 'vue-property-decorator'

import MembershipCard from '~/components/cards/MembershipCard.vue'

@Component({
  components: {
    MembershipCard
  },
  async fetch() {
    await this.$store.dispatch('memberships/getMemberships')

    const memberships = this.$store.state.memberships.memberships.filter(
      (membership) => membership.fund_ref !== null
    )

    memberships.sort((a, b) => {
      return a.start_amount - b.start_amount
    })

    memberships.forEach(membership => {
      if(membership.fund_ref != 1) {
        this.higherMemberships.push(membership)
      } else {
        this.standardMemberships.push(membership)
      }
    })
  }
})
export default class MembershipCardList extends Vue {

  /**
   * Data
   */
  public loading: boolean = false
  public standardMemberships: Array<Object> = []
  public higherMemberships: Array<Object> = []
  public showHigherLevels = false

  /**
   * Methods
   */
  getMemberships(): void {
    this.loading = true
    this.$store
      .dispatch('memberships/getMemberships')
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        // handle error
        this.loading = false
      })
  }

  onViewHigher() {
    this.showHigherLevels = true
  }
}
