
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import MembershipCardList from '~/components/flexible/memberships/MembershipCardList.vue'

@Component({
  components: {
    MembershipCardList
  }
})
export default class Memberships extends Vue {
  @Prop({ type: Object }) block?: any
}
