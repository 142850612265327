
import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import { initCarousel } from '~/modules/carousel/initCarousel'
import QuoteCarousel from '~/components/flexible/quotes/QuoteCarousel.vue'

@Component({
  components: { QuoteCarousel }
})
export default class Quote extends Vue {
  @Prop() block?: any
}
