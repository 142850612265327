
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationObserver } from 'vee-validate'
import TextField from '~/components/ui/fields/TextField.vue'
import TextareaField from '~/components/ui/fields/TextareaField.vue'
import * as email from '~/api/queries/emails.gql'

@Component({
  components: {
    TextField,
    TextareaField,
    ValidationObserver
  }
})
export default class ContactUs extends Vue {
  @Prop({ type: Object }) block: any

  public isOpen: boolean = false
  public messageSent: boolean = false
  public hasError: boolean = false
  public firstName: string = ''
  public lastName: string = ''
  public message: string = ''
  public fromEmail: string = ''

  onClickOpenForm() {
    this.isOpen = true
  }

  handleSubmit(callback: Function): void {
    this.$refs.form
      .validate()
      .then((success) => {
        if (!success || this.noAddress) {
          throw 'Validation failed'
        } else {
          callback()
        }
      })
      .catch((err_) => {
        this.$eventBus.notifyFailure(
          this.$t(
            'There was an error sending your message, please ensure all of the required fields have been completed'
          )
        )
      })
  }

  async sendEmail() {
    this.hasError = false

    const client = this.$apollo

    const response = await client.mutate({
      mutation: email['sendEmail'],
      variables: {
        emailInput: {
          sender: {
            name: `${this.firstName} ${this.lastName}`
          },
          recipient: {
            name: 'HoMA',
            email: this.block.email
          },
          template_code: 'contact_form',
          subject: window.location.pathname,
          extra: {
            message: `${window.location.pathname} - ${this.message} - ${
              this.fromEmail
            }`
          }
        },
        recaptcha: await this.$recaptcha.execute('contact')
      }
    })

    const { data } = response

    if (data.sendEmail) {
      this.messageSent = true
    } else {
      this.hasError = true
    }
  }

  async mounted() {
    await this.$recaptcha.init()
    this.$recaptcha.hideBadge = false
  }

  beforeDestroy() {
    this.$recaptcha.destroy()
  }
}
