
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Mixins } from 'vue-mixin-decorator'
import Fuse from 'fuse.js'
import FaqControls from '~/components/flexible/faq/FaqControls.vue'
import Accordion from '~/components/ui/Accordion.vue'
import FaqLoadMore from '~/components/flexible/faq/FaqLoadMore.vue'
import LoadMoreMixin from '~/components/ui/mixins/LoadMoreMixin'
import { IPaginatable } from '~/components/ui/mixins/Paginatable'

@Component({
  components: {
    FaqControls,
    Accordion,
    FaqLoadMore
  }
})
export default class Faq extends Mixins(LoadMoreMixin) implements IPaginatable {
  @Prop({ type: Array }) questions?: Array<any>
  @Prop({ type: Array }) categories?: Array<any>
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) subtitle?: string

  public searchTerm?: string = ''
  public selectedCategories?: Array<any> = []
  public perPage: number = 4
  public paginatedDataKey: string = 'filtered'
  public showItems: number = 4

  get filtered(): Array<any> {
    let filtered = this.questions
    if (this.searchTerm != '') {
      const options = {
        keys: ['data.question', 'data.answer'],
        threshold: 0.4
      }

      const fuse = new Fuse(this.questions, options)

      filtered = fuse.search(this.searchTerm).map((result) => {
        return {
          data: {
            answer: result.item.data.answer,
            categories: result.item.data.categories,
            question: result.item.data.question
          }
        }
      })
    } else {
      filtered = this.questions
    }

    let found = []

    if (this.selectedCategories.length > 0) {
      found = filtered.filter((item) => {
        return this.selectedCategories.some((category) =>
          item.data.categories.includes(category)
        )
      })
    } else {
      found = filtered
    }

    return found
  }

  onSearchChange(value) {
    this.searchTerm = value
    this.currentPage = 1
    this.paginate()
  }

  onCategoriesChange(value) {
    this.selectedCategories = value
    this.currentPage = 1
    this.paginate()
  }

  onClickLoadMore() {
    this.showItems = this.showItems + this.perPage
    this.currentPage++
    this.paginate()
  }

  mounted() {
    this.paginate()
  }
}
