
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class DonationsFunds extends Vue {
  @Prop(Array) funds?: Array<any>

  public selected = null

  onChange(fund_ref): void {
    this.selected = fund_ref
    this.$emit('donation:fund-selected', this.selected)
  }

  created() {
    const defaultFund =
      this.funds && this.funds.length > 0 ? this.funds[0].fund_ref : null
    this.onChange(defaultFund)
  }
}
