
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import LocationPrimarySvg from '~/static/images/sprites/location--primary.svg'
import ParkingPrimarySvg from '~/static/images/sprites/parking--primary.svg'
import WheelchairPrimarySvg from '~/static/images/sprites/wheelchair--primary.svg'
import Modal from '~/components/ui/Modal.vue'
import OurVenuesDirectionsLink from '~/components/flexible/our-venues/OurVenuesDirectionsLink.vue'
import { Facility } from '~/@types/skyway'

@Component({
  components: {
    LocationPrimarySvg,
    ParkingPrimarySvg,
    WheelchairPrimarySvg,
    Modal,
    OurVenuesDirectionsLink
  }
})
export default class OurVenuesVenueGettingHere extends Vue {
  @Prop({ type: Object }) venue?: Facility
}
