<template>
  <article>
    <Breadcrumbs :enabled="breadcrumbs.enabled" :items="breadcrumbs.items" />
    <Banner v-if="hasBanner" :block="banner" />
    <header v-if="!hasBanner" class="article__header container">
      <h1 class="pt-2" v-html="title"></h1>
    </header>
    <FlexibleContainer v-if="pageHasComponents" :components="components" />
    <Wysiwyg v-else :block="textBlock" />
    <div class="container article__footer">
      Posted by {{ author }} on {{ date }}
    </div>
  </article>
</template>

<script>
import { Component, Mixins } from 'vue-mixin-decorator'
import { PageMixin, HasFlexibleComponents } from '~/mixins/PageMixins'
import Banner from '~/components/flexible/Banner.vue'
import Wysiwyg from '~/components/flexible/Wysiwyg.vue'
import { config } from '~/config'

/**
 * Fallback page component, will match anything not caught by a more specific route
 */
@Component({
  head() {
    return {
      title: this.item.post_title ? this.$decode(this.item.post_title) : ''
    }
  },
  components: {
    Banner,
    Wysiwyg
  }
})
export default class StoryPage extends Mixins(
  PageMixin,
  HasFlexibleComponents
) {
  get hasBanner() {
    try {
      return this.images.featured.sizes
    } catch (err) {
      return false
    }
  }

  get banner() {
    return {
      image_src: this.images.featured,
      title: this.item.post_title,
      full_height: false
    }
  }

  get title() {
    return this.item.post_title
  }

  get title() {
    return this.item.post_title
  }

  get textBlock() {
    return { content: this.item.post_content }
  }

  get date() {
    let date = this.item.post_date
    date = this.$moment(date)
    if (date) {
      return date.format('LL')
    }
  }
}
</script>
<style lang="scss" scoped>
.article__footer {
  padding-top: 20px;
  padding-bottom: 40px;
  font-style: italic;
}
</style>
