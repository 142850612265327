var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"membership-card",class:`membership-card--${_vm.typeKey(_vm.membership.title)}`},[_c('div',{staticClass:"membership-card__header"},[_c('h2',{staticClass:"membership-card__type"},[_vm._v(_vm._s(_vm.membership.title))]),_vm._v(" "),_c('div',{staticClass:"membership-card__cost"},[_c('h4',{staticClass:"membership-card__cost-cost"},[_vm._v("\n        "+_vm._s(_vm.$formatCurrency(_vm.membership.start_amount))+"\n      ")]),_vm._v(" "),(_vm.membership.non_deductible_amount > 0)?_c('small',{staticClass:"membership-card__cost-deductible"},[_vm._v(_vm._s(_vm.$formatCurrency(
            _vm.membership.start_amount - _vm.membership.non_deductible_amount
          ))+"\n        tax-deductible")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"membership-card__content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.membership.benefits)}}),_vm._v(" "),(_vm.membership.benefits != '')?_c('div',{staticClass:"membership-card__content-button"},[(_vm.membership.other_benefits)?_c('Modal',{staticClass:"artifact-actions__modal",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('h2',[_vm._v(_vm._s(_vm.membership.title))])]},proxy:true},{key:"content",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.membership.benefits)}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.membership.other_benefits)}})]},proxy:true},{key:"trigger",fn:function({ open }){return [_c('a',{staticClass:"btn btn--secondary",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return open.apply(null, arguments)}}},[_c('span',[_vm._v("see all benefits")])])]}}],null,false,769288490)}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"membership-card__footer-wrapper"},[_c('div',{staticClass:"membership-card__footer"},[_c('nuxt-link',{staticClass:"btn btn--primary",attrs:{"to":`${_vm.$config.get('URLS').gift_memberships}?membership=${
            _vm.membership.level_ref
          }`}},[_c('span',[_vm._v("buy as a gift")])]),_vm._v(" "),(
          _vm.membershipStatus == 'active' &&
            _vm.membershipLevel == _vm.membership.level_ref &&
            !_vm.isInRenewalPeriod
        )?_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":""}},[_c('span',[_vm._v("Current Level")])]):_vm._e(),_vm._v(" "),(
          _vm.membershipStatus == 'active' &&
            _vm.membershipLevel == _vm.membership.level_ref &&
            _vm.isInRenewalPeriod
        )?_c('button',{staticClass:"btn btn--primary"},[_c('span',[_vm._v("Renew now")])]):(
          _vm.membershipStatus == 'active' &&
            _vm.membershipLevel != _vm.membership.level_ref &&
            _vm.membershipValue < _vm.membership.start_amount
        )?_c('button',{staticClass:"btn btn--primary",on:{"click":_vm.addMembership}},[_c('span',[_vm._v("Upgrade for "+_vm._s(_vm.$formatCurrency(_vm.membership.start_amount)))])]):(
          _vm.membershipStatus == 'active' &&
            _vm.membershipLevel != _vm.membership.level_ref &&
            _vm.membershipValue > _vm.membership.start_amount
        )?_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":""}},[_c('span',[_vm._v("Not available")])]):(_vm.membershipStatus != 'active')?_c('button',{staticClass:"btn btn--primary",on:{"click":_vm.addMembership}},[_c('span',[_vm._v("Buy now for "+_vm._s(_vm.$formatCurrency(_vm.membership.start_amount)))])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }