
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import { cloneDeep } from 'lodash'
import CalendarInformation from '~/components/flexible/calendar/CalendarInformation.vue'
import CalendarBlockCalendar from '~/components/flexible/calendar/CalendarBlockCalendar.vue'

const events = namespace('events')
const packages = namespace('packages')

/**
 * @todo - update so we have the background colour coming through
 */
interface ICalendar {
  title?: string
  description?: string
  prices?: Array<any>
  link?: string
  button_text?: string
  production?: number
  background_colour?: string
  instances_type?: string
  package_type?: string
}

@Component({
  components: {
    CalendarInformation,
    CalendarBlockCalendar,
  },
})
export default class Calendar extends Vue {
  @Prop({ type: Object }) block?: ICalendar

  @events.Action
  getEventAvailability

  @events.State
  event

  @packages.State
  packages

  loading = true

  get calenderStyle() {
    return this.loading ? 'opacity: 0.3' : 'opacity: 1'
  }

  get isPackagesCalendar() {
    return this.block.instances_type === 'package_type'
  }

  get isEventTypeCalendar() {
    return this.block.instances_type === 'event_type'
  }

  async fetchPackages() {
    const packages = await this.$store.dispatch(
      'packages/getPackagesByInternalType',
      this.block.package_type
    )
    return packages
  }

  async fetchEventsByType() {
    const events = await this.$store.dispatch(
      'events/getEventsForCalendar',
      this.block.event_type.toLowerCase().replace(/ /g, '-')
    )

    // concat all instances on to single event - bit of a hack
    // make sure we have the correct event_ref on each instance
    const event = cloneDeep(events.shift())
    event.instances.forEach((i) => {
      i.event_ref = event.event_ref
    })

    events.forEach((e) => {
      const instances = cloneDeep(e.instances)
      instances.forEach((i) => {
        i.event_ref = e.event_ref
      })
      event.instances = event.instances.concat(instances)
    })

    this.$store.commit('events/SET_EVENT', event)

    return events
  }

  async fetchEvent() {
    const event = await this.$store.dispatch(
      'events/getEvent',
      this.block.event
    )
    return event
  }

  async fetchEventAvailability() {
    if (this.event) {
      return await this.getEventAvailability(this.event.event_ref)
    }
  }

  async mounted() {
    this.loading = true
    if (this.isPackagesCalendar) {
      await this.fetchPackages()
    } else if (this.isEventTypeCalendar) {
      await this.fetchEventsByType()
    } else {
      await this.fetchEvent()
      await this.fetchEventAvailability()
    }
    this.loading = false
  }
}
