
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Accordion from '~/components/ui/Accordion.vue'
import OurVenuesVenue from '~/components/flexible/our-venues/OurVenuesVenue.vue'
import { Facility } from '~/@types/skyway'

@Component({
  components: {
    Accordion,
    OurVenuesVenue
  }
})
export default class OurVenuesAccordionGroup extends Vue {
  @Prop() venues!: Array<Facility>

  toLowerDashed(string): string {
    return string.replace(/ /g, '-').toLowerCase()
  }
}
