
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class AbstractPagination extends Vue {
  @Prop({ type: Number }) perPage?: number
  @Prop({ type: Array }) data?: Array<any>
  @Prop({ type: Number }) pageToOpen?: number

  get totalPages() {
    return this.data && this.dataLength > this.perPage
      ? Math.ceil(this.dataLength / this.perPage)
      : 1
  }

  get start() {
    let page = this.pageToOpen != undefined ? this.pageToOpen : 1
    return (page - 1) * this.perPage + 1
  }

  get stop() {
    let page = this.pageToOpen != undefined ? this.pageToOpen : 1
    return page * this.perPage
  }

  get dataLength() {
    return this.data !== undefined ? this.data.length : 0
  }
}
