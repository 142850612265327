
import { Component, Vue, Watch, Prop } from 'nuxt-property-decorator'
import { initCarousel } from '~/modules/carousel/initCarousel'

import EventCard from '~/components/cards/EventCard.vue'
import { Event } from '~/@types/skyway'

@Component({
  components: {
    EventCard
  }
})
export default class OnThisWeekCarousel extends Vue {
  public readonly $refs!: { carousel: HTMLElement }
  public carousel?: any = null

  @Prop() events?: Array<Event>

  mounted(): void {
    if (this.events.length > 0) {
      this.initCarousel()
    }
  }

  initCarousel(): void {
    Vue.nextTick(() => {
      if (this.$refs.carousel) {
        const paginationContainer = this.$refs.carousel.querySelector(
          '[data-pagination]'
        )
        const carouselElement = this.$refs.carousel.querySelector(
          '[data-carousel]'
        )
        this.carousel = initCarousel({
          carouselElement,
          paginationContainer,
          perPage: {
            0: 1,
            640: 2,
            1024: 3
          },
          duration: 500,
          onInit() {
            this._addSlideIds(this)
            this.innerElements[0].parentNode.setAttribute(
              'data-slide-state',
              'active'
            )
          },
          onChange() {
            this._updatePagination(this)
            this._disablePaginationArrows(this, paginationContainer)
            this._assignSlideStates(this)
          },
          loop: false
        })
      }
    })
  }

  beforeDestroy(): void {
    if (this.carousel) {
      this.carousel.destroy()
    }
  }
}
