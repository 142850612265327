
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import {
  videoType,
  extractPatterns
} from '~/modules/core/helpers/validateVideoUrl'

@Component()
export default class Embed extends Vue {
  @Prop({ type: Object }) block?: any

  public src: string = ''
  public facebookReady: boolean = false

  public options: any = {
    plugins: [
      {
        name: 'facebook'
      }
    ]
  }

  get videoSrc(): void {
    const type = videoType(this.block.embed)
    let src = ''
    switch (type) {
      case 'vimeo':
        src = this.getVimeoSrcUrl()
        break
      case 'youtube':
        src = this.getYoutubeSrcUrl()
        break
    }
    return src
  }

  getVimeoSrcUrl(): string {
    // the embed block already returns the correct embed URL, so we shouldn't need to process it here
    return this.block.embed

    // const firstPart = this.block.embed.split('?')[0].split('/')
    // const vid = firstPart[firstPart.length - 1]

    // let src = `//player.vimeo.com/video/${vid}`

    // // Strip extra parameters;
    // src = src.split('?')[0]

    // return src
  }

  getYoutubeSrcUrl(): string {
    let src = this.block.embed.replace(
      extractPatterns['youtube'],
      `//www.youtube.com/$1`
    )

    return src
  }

  mounted(): void {
    const installFacebookSdkScript = (d, s, id) => {
      if (d.getElementById(id)) {
        this.facebookSdkReady = true
        return
      }
      let fjs = d.getElementsByTagName(s)[0]
      let js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/all.js#xfbml=1'
      fjs.parentNode.insertBefore(js, fjs)
    }
    installFacebookSdkScript(document, 'script', 'facebook-jssdk')
    this.facebookReady = true

    Vue.nextTick(() => {
      window.fbAsyncInit = function() {
        FB.init({
          xfbml: true,
          version: 'v5.0'
        })
      }
    })

    if (this.$lazyload) {
      this.$lazyload.update()
    }
  }
}
