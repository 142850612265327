
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import OurVenuesAccordionGroup from '~/components/flexible/our-venues/OurVenuesAccordionGroup.vue'
import OurVenuesTabGroup from '~/components/flexible/our-venues/OurVenuesTabGroup.vue'
import OurVenuesVenue from '~/components/flexible/our-venues/OurVenuesVenue.vue'
import { Venue } from '~/@types/skyway'

const venues = namespace('venues')

@Component({
  components: { OurVenuesAccordionGroup, OurVenuesTabGroup, OurVenuesVenue },
  async fetch() {
    if (!this.venues.length > 0) {
      return await this.fetchVenues()
    }
  },
})
export default class OurVenuesContainer extends Vue {
  @Prop({ type: Object }) block?: any

  @venues.Getter
  private getVenues

  public loading: boolean = false

  get venues(): Array<Venue> {
    return this.getVenues || []
  }

  fetchVenues(): Promise<any> {
    this.loading = true
    return this.$store
      .dispatch('venues/getFacilities')
      .then((res) => {
        this.loading = false
      })
      .catch((err_) => {
        this.loading = false
      })
  }
}
