
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import PopOver from '~/components/ui/PopOver.vue'
import CalendarBlockDayAvailability from '~/components/flexible/calendar/CalendarBlockDayAvailability.vue'
import { Instance } from '~/@types/skyway'
import { sumBy } from 'lodash'

@Component({
  components: {
    PopOver,
    CalendarBlockDayAvailability,
  },
})
export default class CalendarDay extends Vue {
  @Prop() instances?: Array<Instance>
  @Prop() zoneFilter?: number[]
  @Prop() date!: number
  @Prop() month!: string
  @Prop() year!: string
  @Prop() limitedAvailability!: number
  @Prop({ type: Array }) packages?: any[]

  protected dayHovered: boolean = false

  get dateContext(): string {
    const month = this.$moment().month(this.month).format('MM')
    const day = this.$moment(
      `${this.year}-${month}-${this.date}`,
      'YYYY-MM-D'
    ).format('DD')

    return `${this.year}-${month}-${day}`
  }

  get packagesFiltered() {
    const selectedDate = this.$moment(this.dateContext, 'YYYY-MM-DD')
    const packages =
      (this.packages &&
        this.packages.filter((p) => {
          return p.instances.find((i) => {
            const instanceDateTime = this.$moment(i.date_time).format()
            return selectedDate.isSame(instanceDateTime, 'day')
          })
        })) ||
      []
    return packages
  }

  get hasEvents(): boolean {
    return this.instances !== undefined && this.instances.length > 0
  }

  get showPopOver(): boolean {
    return Boolean(
      this.packages &&
        this.packages.length > 0 &&
        this.dayHovered &&
        this.$mq !== 'sm' &&
        this.$mq !== 'md'
    )
  }

  get availability(): number {
    if (this.hasEvents && this.instances) {
      if (this.zoneFilter && this.zoneFilter.length) {
        let availability = 0
        for (const i in this.instances) {
          const instance = this.instances[i]
          console.log({ instance })
          const zones =
            instance &&
            instance.availability &&
            instance.availability.filter(
              (z) => z && this.zoneFilter.includes(Number(z.zone_ref))
            )
          console.log({ zones })
          availability += sumBy(zones, (z) => z.availability)
        }
        return availability
      } else {
        return Math.min(
          ...this.instances.map((i) => Number(i.total_availability))
        )
      }
    }
    return 0
  }

  get soldOut(): boolean {
    return this.hasEvents && this.availability === 0
  }

  get isLimitedAvailability(): boolean {
    return this.hasEvents && !this.soldOut
      ? this.availability < this.limitedAvailability
      : false
  }

  get isFuture(): boolean {
    const instance_date = this.$moment(
      `${this.month} ${this.date < 10 ? `0${this.date}` : this.date} ${
        this.year
      }`,
      'MMMM DD YYYY'
    )

    return this.$moment() <= instance_date
  }

  onSelectDay() {
    this.$emit('select-day', this.date)
  }

  onMouseOverDay() {
    if (this.hasEvents && this.isFuture) {
      this.dayHovered = true
    }
  }

  onMouseLeave() {
    setTimeout(() => {
      this.dayHovered = false
    }, 25)
  }
}
