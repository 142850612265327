
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class AbstractRadio extends Vue {
  @Prop() value?: any
  @Prop() val?: any
  @Prop() id?: any
  @Prop({ type: String }) label?: string

  public checkedProxy?: boolean = false

  handleClick() {
    const value = [].concat(this.value)

    if (!this.checkedProxy && value.includes(this.val)) {
      value.splice(value.indexOf(this.val), 1)
    } else {
      value.push(this.val)
    }
    this.$emit('input', value)
  }

  get isChecked() {
    return this.value.includes(this.val)
  }

  created() {
    if (this.value.includes(this.val)) {
      this.checkedProxy = true
    }
  }
}
