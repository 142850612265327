
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { initCarousel } from '~/modules/carousel/initCarousel'
import ImageCarouselImage from '~/components/flexible/image-gallery/ImageCarouselImage'

@Component({
  components: {
    ImageCarouselImage,
  },
})
export default class ImageCarousel extends Vue {
  @Prop({ type: Object }) block?: {}
  @Prop({ type: Boolean, default: true }) isContained?: Boolean

  protected paginationContainer = null
  protected carouselElement = null
  protected imageHeight = 500
  protected image = null
  protected heading = null
  protected timeout = null

  initCarousel(): void {
    Vue.nextTick(() => {
      this.paginationContainer =
        this.$refs.carousel.querySelector('[data-pagination]')
      this.carouselElement =
        this.$refs.carousel.querySelector('[data-carousel]')

      const self = this

      initCarousel({
        carouselElement: self.carouselElement,
        paginationContainer: self.paginationContainer,
        perPage: 1,
        duration: 500,
        onInit() {
          this._addSlideIds(this)
          this.innerElements[0].parentNode.setAttribute(
            'data-slide-state',
            'active'
          )
          const elementCount = this.innerElements.length
          const containerWidth = self.carouselElement.children[0].offsetWidth

          self.carouselElement.style.width = `${
            containerWidth / elementCount
          }px`

          this.innerElements.forEach((slide) => {
            slide.parentNode.style.width = `${containerWidth / elementCount}px`
          })
          self.$AOS.refresh()
        },
        onChange() {
          this._updatePagination(this)
          this._disablePaginationArrows(this, self.paginationContainer)
          this._assignSlideStates(this)
        },
      })

      this.image = this.carouselElement.querySelector('.carousel-image')
      this.heading = this.$refs.carousel.querySelector(
        '.block-cta-carousel__heading'
      )

      if (this.image) {
        this.imageHeight = this.image.clientHeight

        if (this.heading) {
          this.imageHeight = this.imageHeight + this.heading.clientHeight
        }
      }
    })
  }

  handleResize() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      if (this.image) {
        this.imageHeight = this.image.clientHeight
        if (this.heading) {
          this.imageHeight = this.imageHeight + this.heading.clientHeight
        }
      }
    })
  }

  mounted() {
    if (this.block.images && this.block.images.length > 1) {
      this.initCarousel()
    }
    window.addEventListener('resize', this.handleResize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
