/**
 * Patterns used to validate a video
 * @object
 */
export const patterns = {
  youtube: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/,
  vimeo: /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g,
  playlist: /^.*(youtu.be\/|list=)([^#\&\?]*).*/
}

/**
 * Patterns used to extract video id
 * @object
 */
export const extractPatterns = {
  youtube: /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g,
  vimeo: /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g
}

export const validateVideoUrl = (url) => {
  let type = false

  for (let key in patterns) {
    if (patterns.hasOwnProperty(key) && url.match(patterns[key])) {
      type = key
    }
  }

  return type
}

/**
 * Function to return the type of video
 * @function
 * @param { String }
 */
export const videoType = (url) => {
  return validateVideoUrl(url)
}
