
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import DonationsFunds from '~/components/flexible/donations/DonationsFunds.vue'
import DonationTextField from '~/components/ui/fields/DonationTextField.vue'
import { CreateCustomerServiceIssueInput } from '../../@types/skyway'
import Loader from '~/components/ui/Loader.vue'

@Component({
  components: {
    DonationsFunds,
    DonationTextField,
    Loader,
  },
})
export default class Donations extends Vue {
  /**
   * Props
   */
  @Prop({ type: Object }) block?: any

  /**
   * Data
   */
  protected amounts: Array<any> = []
  protected selectedAmount: number | string = 25
  protected customAmount: number | string | null = null
  protected fundRef: number = this.$config.get('DONATION_FUND_REF')
  protected useCustomAmount: boolean = false
  protected inHonourOf: string = ''
  protected loading: boolean = false
  protected hasDonated: boolean = false

  /**
   * Computed
   */
  get addContributionInput(): AddContributionInput {
    return {
      fund_ref: this.fundRef,
      amount:
        this.selectedAmount == 0
          ? parseInt(this.customAmount)
          : parseInt(this.selectedAmount),
    }
  }

  get donateButtonText(): string {
    const amount = this.useCustomAmount
      ? parseFloat(this.customAmount)
      : this.selectedAmount
    const prefix = 'Donate'

    return amount && amount >= 1
      ? `${prefix} ${this.getFormattedCurrency(amount)}`
      : prefix
  }

  get validContribution(): boolean {
    if (this.useCustomAmount) {
      return this.customAmount != null && this.customAmount >= 1
    } else {
      return this.selectedAmount >= 1
    }
  }

  get image(): string {
    if (this.block.image.sizes && this.block.image.sizes.medium_large) {
      return this.block.image.sizes.medium_large
    } else {
      return this.block.image.url
    }
  }

  get amountDescription(): string {
    const selectedAmountItem = this.amounts.find((amount) => {
      return amount.value == this.selectedAmount
    })
    return selectedAmountItem ? selectedAmountItem.description : null
  }

  get csiInput(): CreateCustomerServiceIssueInput {
    return {
      activity_type_ref: 62,
      activity_category_ref: 25,
      contact_type_ref: 7,
      note: `In Honor: ${this.inHonourOf}`,
    }
  }

  /**
   * Methods
   */
  updateAmount(amt) {
    if (amt == 0) {
      this.useCustomAmount = true
    } else {
      this.useCustomAmount = false
    }
    this.selectedAmount = amt
  }

  async onClickDonate(): void {
    if (this.validContribution) {
      this.loading = true
      const result = await this.$store.dispatch(
        'donation/addContribution',
        this.addContributionInput
      )

      if (result) {
        this.$store
          .dispatch('basket/getBasket', 'network-only')
          .then(() => {
            if (this.inHonourOf != '') {
              // Build the CSI Input object and save it to local storage
              // so that it can be retrieved after page reload then
              // sent upon a successful purchase
              sessionStorage.setItem(
                'donation_csi',
                JSON.stringify(this.csiInput)
              )
            }

            this.loading = false

            this.$eventBus.notifySuccess(
              'Thank You! Your donation has been added to your cart'
            )

            this.$router.push(this.$config.get('URLS').basket)
          })
          .catch((_err) => {
            this.$eventBus.notifyFailure(
              'Please select an amount of at least $1'
            )
          })
      }
    } else {
      this.$eventBus.notifyFailure('Please select an amount of at least $1')
      this.loading = false
    }
  }

  protected getFormattedCurrency(amount: number): string {
    if (parseInt(amount) > 0) {
      return Number.isInteger(amount)
        ? this.$formatCurrency(amount).slice(0, -3)
        : this.$formatCurrency(amount)
    }
    return ''
  }

  protected onCustomAmountInput(input): void {
    this.customAmount = input
  }

  protected onFundSelected(input): void {
    this.fundRef = input
  }

  /**
   * Created
   */
  created(): void {
    this.amounts = this.block.amounts.map((amount) => {
      return {
        value: parseFloat(amount.amount),
        description: amount.description,
      }
    })
  }
}
