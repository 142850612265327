
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Package } from '~/@types/skyway'
// @ts-ignore
import ChevronLeftSvg from '~/static/images/sprites/chevron-left.svg'
import CalendarPackageZones from '~/components/flexible/calendar/CalendarPackageZones.vue'

@Component({
  components: {
    ChevronLeftSvg,
    CalendarPackageZones,
  },
})
export default class CalendarPackages extends Vue {
  @Prop() packages!: Package[]

  // Will be package id
  public selected: number = 0

  get zones() {
    if (this.has_price_types) {
      return this.packages[0].price_types[0].prices
    } else {
      return null
    }
  }

  get has_price_types(): boolean {
    if (
      this.packages &&
      this.packages.length &&
      this.packages[0].price_types &&
      this.packages[0].price_types[0]
    ) {
      return true
    } else {
      return false
    }
  }

  onSelectZone(id: OID): void {
    this.selected = id
    this.$store.commit('selection/SET_ZONE', id)
  }

  /**
   * @todo handle error state
   */
  onClickBookNow(): void {
    if (this.selected) {
      this.$router.push(`/purchase/packages/${this.packages[0].package_ref}/`)
    }
  }

  mounted() {
    if (this.has_price_types && this.packages[0].price_types.length === 1) {
      this.$store.commit('selection/SET_PACKAGE', this.packages[0].package_ref)
      this.$store.commit(
        'selection/SET_ZONE',
        this.packages[0].price_types[0].prices[0].zone_ref
      )
      this.$router.push(`/purchase/packages/${this.packages[0].id}/`)
    }
  }
}
