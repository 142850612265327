
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
// import OnThisWeek from '~/components/flexible/related/OnThisWeek.vue'
import CardCarousel from '~/components/flexible/related/CardCarousel.vue'
import CardGrid from '~/components/flexible/related/CardGrid.vue'

@Component({
  components: {
    // OnThisWeek,
    CardCarousel,
    CardGrid
  }
})
export default class RelatedContent extends Vue {
  @Prop({ type: Object }) block: any
}
