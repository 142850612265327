
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { initCarousel } from '~/modules/carousel/initCarousel'
import InstagramCard from '~/components/flexible/social/InstagramCard.vue'
import SvgInstagram from '~/static/images/sprites/in.svg'

type InstagramPost = {
  imageSrc: string
  url: string
  content: string
  username: string
}

@Component({
  components: {
    InstagramCard,
    SvgInstagram
  }
})
export default class InstagramFeed extends Vue {
  @Prop({ type: Object }) block: any

  private parsedCards = []

  get cards(): Array<InstagramPost> {
    return this.parsedCards
  }

  initCarousel(): void {
    Vue.nextTick(() => {
      if (this.$refs.carousel) {
        const paginationContainer = this.$refs.carousel.querySelector(
          '[data-pagination]'
        )
        const carouselElement = this.$refs.carousel.querySelector(
          '[data-carousel]'
        )
        initCarousel({
          carouselElement,
          paginationContainer,
          perPage: {
            0: 1,
            768: 3,
            1024: 4
          },
          duration: 500,
          onInit() {
            this._addSlideIds(this)
            if (this.innerElements && this.innerElements.length) {
              this.innerElements[0].parentNode.setAttribute(
                'data-slide-state',
                'active'
              )
            }
          },
          onChange() {
            this._updatePagination(this)
            this._disablePaginationArrows(this, paginationContainer)
            this._assignSlideStates(this)
          },
          loop: false
        })
      }
    })
  }

  parseCards() {
    return new Promise((res, rej) => {
      if (document !== undefined) {
        try {
          const dom = document.createElement('div')
          dom.innerHTML = JSON.parse(this.block.feed)
          const posts = dom.querySelectorAll('.sbi_photo')
          const cards = []
          if (posts && posts.length) {
            posts.forEach((card) => {
              const imgs = JSON.parse(card.dataset.imgSrcSet)
              cards.push({
                imageSrc: imgs[150]
              })
            })
          }
          this.parsedCards = cards
          res()
        } catch (err_) {
          this.parsedCards = []
          rej()
        }
      }
    })
  }

  mounted(): void {
    this.parseCards()
      .then(() => {
        this.initCarousel()
      })
      .catch((err_) => {
        // console.log(err_)
      })
  }
}
