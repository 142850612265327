var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"calendar-block__calendar"},[_c('div',{staticClass:"featured-calendar__wrapper"},[_c('div',{staticClass:"featured-calendar",attrs:{"data-featured-calendar":""}},[_c('div',{staticClass:"featured-calendar__wrapper",attrs:{"data-featured-calendar-wrapper":""}},[(!_vm.daySelected)?_c('div',{staticClass:"featured-calendar__calendar"},[_c('h4',{staticClass:"featured-calendar__heading",domProps:{"textContent":_vm._s(_vm.loading ? 'Loading...' : 'Choose a day')}}),_vm._v(" "),(
              !_vm.loading &&
              ((!_vm.isPackagesCalendar && !_vm.hasEventInstances) ||
                (_vm.isPackagesCalendar && !_vm.packages))
            )?[_vm._m(0)]:[_c('div',{staticClass:"featured-calendar__controls",attrs:{"data-calendar-controls":""}},[_c('button',{staticClass:"btn featured-calendar__controls-btn",on:{"click":function($event){$event.preventDefault();return _vm.onClickLast.apply(null, arguments)}}},[_c('SvgChevronLeft'),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v("View last month's dates")])],1),_vm._v(" "),_c('p',{staticClass:"featured-calendar__controls-title"},[_vm._v("\n                "+_vm._s(_vm.month)+" "+_vm._s(_vm.year)+"\n              ")]),_vm._v(" "),_c('button',{staticClass:"btn featured-calendar__controls-btn",on:{"click":function($event){$event.preventDefault();return _vm.onClickNext.apply(null, arguments)}}},[_c('SvgChevronRight'),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v("View next month's dates")])],1)]),_vm._v(" "),_c('div',{staticClass:"featured-calendar__label-container"},_vm._l((_vm.days),function(day){return _c('div',{key:day,staticClass:"featured-calendar__label"},[_vm._v("\n                "+_vm._s(day)+"\n              ")])}),0),_vm._v(" "),_c('div',{staticClass:"featured-calendar__container",attrs:{"data-calendar-view":""}},[_vm._l((_vm.firstDayOfMonth),function(blank,index){return _c('div',{key:`${index}-${_vm.month}-blank`,staticClass:"calendar__block calendar__block--prev calendar__block--no-events"})}),_vm._v(" "),_vm._l((_vm.daysInMonth),function(date){return _c('CalendarDay',{key:`${date}-${_vm.month}`,attrs:{"instances":_vm.instancesByDay(date),"packages":_vm.packages,"date":date,"month":_vm.month,"year":_vm.year,"zone-filter":_vm.event && _vm.event.type === 'field-trip'
                    ? [97, 226, 277, 229]
                    : [],"limited-availability":_vm.event && _vm.event.type === 'field-trip'
                    ? 0
                    : _vm.limitedAvailability},on:{"select-day":_vm.onSelectDay,"mouseover-day":_vm.onMouseOverDay}})})],2),_vm._v(" "),_c('div',{staticClass:"featured-calendar__controls featured-calendar__controls--bottom"},[_c('span',{staticClass:"low-availability"},[_vm._v(_vm._s(_vm.$t('Low availability')))])])]],2):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isPackagesCalendar && _vm.daySelected)?_c('CalendarPackages',{attrs:{"packages":_vm.selectedPackages},on:{"calendar-packages:go-back":_vm.onClickBack}}):(
              !_vm.isPackagesCalendar &&
              _vm.daySelected &&
              _vm.instances &&
              _vm.instances.length > 1
            )?_c('CalendarPerformances',{attrs:{"instances":_vm.instances,"type":_vm.event.type},on:{"calendar-performances:go-back":_vm.onClickBack}}):(!_vm.isPackagesCalendar && _vm.daySelected)?_c('CalendarZones',{attrs:{"event":_vm.event},on:{"calendar-performances:go-back":_vm.onClickBack}}):_vm._e()],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"featured-calendar__label-container"},[_c('p',[_vm._v("There are no available tickets and dates at this time.")])])
}]

export { render, staticRenderFns }