
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CtaBlock } from '~/components/flexible/types/Cta'

@Component
export default class Cta extends Vue {
  @Prop({ type: Object }) block?: CtaBlock
  @Prop(Number) index
  @Prop({ type: String }) modifier?: string

  onClickCta(): void {
    // if internal link and hasnt been set to target _blank, use nuxt link
    if (this.isInternal && this.block.button_target == '' && !this.isSubdomain) {
      this.$router.push(this.block.url.replace(/^https?:\/\/(..)+\//,'/'))
    } else {
      window.open(this.block.url, '_blank')
    }
  }

  get isSubdomain(): boolean {
    const url = this.block!.url!
    const regex = new RegExp(/([\w-]+\.[\w-]+\.\w+)/)

    return regex.test(url);
  }

  get isInternal(): boolean {
    if (this.block.url && (this.block.url.includes(this.$config.get('APP_URL').replace(/^https?:\/\//,'')) || !this.block.url.includes('http'))) {
      return true
    } else {
      return false
    }
  }

  mounted(): void {
    Vue.nextTick(() => {
      this.$lazyload.update()
    })
  }
}
