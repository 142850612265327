
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Cta from '~/components/flexible/ctas/Cta'

@Component
export default class CtaSingle extends Cta {
  get bgStyle(): string {
    if (this.block.image_src) {
      return `background-image:url(${this.block.image_src})`
    } else {
      return ''
    }
  }
}
