import { Vue } from 'nuxt-property-decorator'
import { Mixin } from 'vue-mixin-decorator'

export interface IPaginatable {
  perPage: number
  paginatedDataKey: string
}

/**
 * Apply the paginatable mixin to provide helper functions to components
 * that use a load more
 */
@Mixin
export default class LoadMoreMixin extends Vue {
  public currentPage: number = 1
  public paginated: Array<any> = []
  public paginatedDataKey: string = ''
  public perPage: number = 9

  get totalPages() {
    return this[this.paginatedDataKey] && this.dataLength > this.perPage
      ? Math.ceil(this.dataLength / this.perPage)
      : 1
  }

  get start() {
    return 1
  }

  get stop() {
    let page = this.currentPage != undefined ? this.currentPage : 1
    return page * this.perPage
  }

  get dataLength() {
    return this[this.paginatedDataKey] !== undefined
      ? this[this.paginatedDataKey].length
      : 0
  }

  paginate() {
    let paginated = []

    if (this[this.paginatedDataKey].length > 0) {
      this[this.paginatedDataKey]
        .slice(this.start - 1, this.stop)
        .forEach((item, index) => {
          paginated[index] = this[this.paginatedDataKey][index]
        })
    }

    this.paginated = paginated
  }

  get showLoadMore() {
    return this.currentPage < this.totalPages
  }

  onClickLoadMore() {
    this.currentPage != this.totalPages ? this.currentPage++ : this.currentPage
    this.paginate()
  }
}
